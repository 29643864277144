import { loadStripe } from '@stripe/stripe-js'
import Axios from 'axios'

const apiUrl = (path: string) => {
  const baseUrl =
    window.location.hostname === 'localhost'
      ? `https://y3as74glbh.execute-api.ap-northeast-1.amazonaws.com/test`
      : `https://0ze6c8qhv6.execute-api.ap-northeast-1.amazonaws.com/develop`
  return `${baseUrl}/${path}`
}

// TODO need to switch live/test keys
const STRIPE_DEV_PUBLIC_KEY = 'pk_test_HMcw960sxC8Lcgv23AAgKa9D0064E2dENp'
const STRIPE_LOCAL_PUBLIC_KEY =
  'pk_test_51HmbPYATRBeZEDBKFkINfVkpjzWy006Md67jECLeONVkNzIJcYlimBX5VfvdnHnT4VcE0w40bx3gvqytjJINyura001j2RuK7C'

const requestSubscriptionSession = async (
  userId: string,
  paymentPlanId: string,
) => {
  const response = await Axios.post(apiUrl('subscriptions/requests'), {
    userId,
    paymentPlanId,
  })
  if (response.status !== 200) {
    console.error(response.data.error)
    throw new Error('request failed.')
  }
  return response.data.data.sessionId
}

export const requestSubscription = async (
  userId: string,
  paymentPlanId: string,
) => {
  const key =
    window.location.hostname === 'localhost'
      ? STRIPE_LOCAL_PUBLIC_KEY
      : STRIPE_DEV_PUBLIC_KEY
  const sessionId = await requestSubscriptionSession(userId, paymentPlanId)
  const stripe = await loadStripe(key)
  await stripe?.redirectToCheckout({
    sessionId,
  })
}

export const cancelSubscription = async (userId: string) => {
  const response = await Axios.post(apiUrl('subscriptions/cancel'), {
    userId,
  })
  if (response.status !== 200) {
    console.error(response.data.error)
    throw new Error('request failed.')
  }
}

export const pay = async (
  userId: string,
  paymentPlanId: string,
  paymentMethodId: string,
) => {
  const response = await Axios.post(apiUrl('payment'), {
    userId,
    paymentPlanId,
    paymentMethodId,
  })
  if (response.status !== 200) {
    console.error(response.data.error)
    throw new Error('request failed.')
  }
}

export const addPaymentMethod = async (
  userId: string,
  cardNumber: string,
  expMonth: string,
  expYear: string,
  cvc: string,
  name: string,
  email: string,
  country: string,
) => {
  const response = await Axios.post(
    apiUrl(`account/${userId}/paymentMethods`),
    {
      userId,
      cardInfo: { cardNumber, expMonth, expYear, cvc },
      billingDetails: {
        name,
        email,
        address: {
          country,
        },
      },
    },
  )
  if (response.status !== 200) {
    console.error(response.data.error)
    throw new Error('request failed.')
  }
}
