import { Growl } from 'primereact/growl'
import React, { Component } from 'react'
interface Props {
  successRef: any
  errorRef: any
}
class GrowlMessage extends Component<Props> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <Growl
          style={{ backgroundColor: '#20B716' }}
          ref={this.props.successRef}
        />
        <Growl
          style={{ backgroundColor: '#D61813' }}
          ref={this.props.errorRef}
        />
      </>
    )
  }
}

export default GrowlMessage
