import {
  GET_CURRENTMEETING_BEGIN,
  GET_CURRENTMEETING_FAILURE,
  GET_CURRENTMEETING_SUCCESS,
  GET_PROFILE_BEGIN,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  SET_CURRENTFEELING_BEGIN,
  SET_CURRENTFEELING_FAILURE,
  SET_CURRENTFEELING_SUCCESS,
  SET_CURRENTMEETING_BEGIN,
  SET_CURRENTMEETING_FAILURE,
  SET_CURRENTMEETING_SUCCESS,
  SET_PROFILE_BEGIN,
  SET_PROFILE_FAILURE,
  SET_PROFILE_SUCCESS,
} from '../actions/1_ActionConstants'
import FirebaseService from '../services/Firebase'

export const setCurrentMeetingBegin = () => ({
  type: SET_CURRENTMEETING_BEGIN,
})

export const setCurrentMeetingSuccess = (currentMeeting: any) => ({
  type: SET_CURRENTMEETING_SUCCESS,
  payload: { currentMeeting },
})

export const setCurrentMeetingFailure = (error: any) => ({
  type: SET_CURRENTMEETING_FAILURE,
  payload: { error },
})

export const getCurrentMeetingBegin = () => ({
  type: GET_CURRENTMEETING_BEGIN,
})

export const getCurrentMeetingSuccess = (currentMeeting: any) => ({
  type: GET_CURRENTMEETING_SUCCESS,
  payload: { currentMeeting },
})

export const getCurrentMeetingFailure = (error: any) => ({
  type: GET_CURRENTMEETING_FAILURE,
  payload: { error },
})

export const setCurrentFeelingBegin = () => ({
  type: SET_CURRENTFEELING_BEGIN,
})

export const setCurrentFeelingSuccess = (currentFeeling: any) => ({
  type: SET_CURRENTFEELING_SUCCESS,
  payload: { currentFeeling },
})

export const setCurrentFeelingFailure = (error: any) => ({
  type: SET_CURRENTFEELING_FAILURE,
  payload: { error },
})

//profile
export const setProfileBegin = () => ({
  type: SET_PROFILE_BEGIN,
})

export const setProfileSuccess = (profile: any) => ({
  type: SET_PROFILE_SUCCESS,
  payload: { profile },
})

export const setProfileFailure = (error: any) => ({
  type: SET_PROFILE_FAILURE,
  payload: { error },
})

export const getProfileBegin = () => ({
  type: GET_PROFILE_BEGIN,
})

export const getProfileSuccess = (profile: any) => ({
  type: GET_PROFILE_SUCCESS,
  payload: { profile },
})

export const getProfileFailure = (error: any) => ({
  type: GET_PROFILE_FAILURE,
  payload: { error },
})

export function setCurrentMeeting(meetingObject: any) {
  return (dispatch: any, getState: any) => {
    dispatch(setCurrentMeetingBegin())
    if (meetingObject) {
      setCurrentFeeling({}) //for new meeting, set aside old feelings
      dispatch(setCurrentMeetingSuccess(meetingObject))
      return true
    } else {
      dispatch(setCurrentMeetingFailure('error'))
      return false
    }
  }
}

export function getCurrentMeeting(meetingId: any) {
  return (dispatch: any, getState: any) =>
    new Promise((resolve, reject) => {
      dispatch(getCurrentMeetingBegin())
      FirebaseService.getCurrentMeetingDetails(meetingId)
        .then((res) => {
          console.log('found meeting...')
          var doc: any = res.data()
          var meetingObj = {
            ...doc,
            date: doc.date.toDate(),
            enddate: doc.enddate.toDate(),
            id: meetingId,
          }
          dispatch(getCurrentMeetingSuccess(meetingObj))
          resolve(meetingObj)
        })
        .catch((err) => {
          dispatch(getCurrentMeetingFailure('error'))
          reject(err)
        })
    })
}

export function setCurrentFeeling(feelingObject: any) {
  return (dispatch: any, getState: any) =>
    new Promise((resolve, reject) => {
      dispatch(setCurrentFeelingBegin())
      if (feelingObject) {
        dispatch(setCurrentFeelingSuccess(feelingObject))
        resolve(feelingObject)
      } else {
        dispatch(setCurrentFeelingFailure('error'))
        reject('error')
      }
    })
}

export function setProfile(profile: any, docId: any) {
  return (dispatch: any, getState: any) =>
    new Promise((resolve, reject) => {
      dispatch(setProfileBegin())
      if (profile && profile.uid) {
        var user: any = FirebaseService.getCurrentUser()

        user
          .updateProfile({
            ...profile,
          })
          .then((res: any) => {
            console.log('Updated profile successfully in userprofile')
            const promise: any = FirebaseService.updateUserProfile(
              profile,
              docId,
            )
            promise
              .then((res: any) => {
                console.log('Updated profile successfully in db')
                dispatch(setProfileSuccess(profile))
                resolve(profile)
              })
              .catch((err: any) => {
                console.log('Error updating profile in db')
                dispatch(setProfileFailure('error'))
                reject(err)
              })
          })
          .catch((err: any) => {
            console.log('Error updating profile in userprofile')
            dispatch(setProfileFailure('error'))
            reject(err)
          })
      } else {
        dispatch(setProfileSuccess(profile))
        resolve(profile)
      }
    })
}

export function getCurrentUserProfile() {
  return (dispatch: any, getState: any) =>
    new Promise((resolve, reject) => {
      dispatch(getProfileBegin())
      var user: any = FirebaseService.getCurrentUser()

      FirebaseService.getUserProfile(user.uid)
        .then((res) => {
          if (res.empty) {
            var profile = {
              name: '',
              nickname: '',
              description: '',
              date: '',
              photoURL: null,
              email: user.email,
              uid: user.uid,
              docId: null,
            }
            dispatch(getProfileSuccess(profile))
            resolve(profile)
          }
          res.forEach((entry) => {
            var doc = entry.data()
            console.log(
              'found user with uid: ',
              doc.uid,
              ' and name: ',
              doc.name,
            )
            var profile = {
              name: doc.name,
              nickname: doc.nickname,
              description: doc.description,
              date: doc.dob ? doc.dob.toDate() : null,
              photoURL: doc.photoURL,
              email: doc.email,
              uid: doc.uid,
              docId: entry.id,
            }
            dispatch(getProfileSuccess(profile))
            resolve(profile)
          })
        })
        .catch((err) => {
          console.log('error fetching user profile')
          dispatch(getProfileFailure('error'))
          reject(err)
        })
    })
}
