import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import {
  linkLogin,
  linkSignup,
  linkPageDashboard,
  linkPageHome,
} from '../routes'
import { connect } from 'react-redux'
import FirebaseService from '../services/Firebase'
import {
  setCurrentFeeling,
  setCurrentMeeting,
  setProfile,
} from '../actions/CurrentMeetingActions'
import profileAdd from '../assets/img/premeetinguser.png'
interface Props {
  profile: any
  dispatch: any
}
interface State {
  expandHeader: boolean
}
class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      expandHeader: false,
    }
  }

  componentDidUpdate() {
    if (window.location.href.includes('/profile')) {
      return
    }
    if (this.props.profile) {
      if (this.props.profile.photoURL) {
      } else {
        window.location.href = '/profile'
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="w-full max-w-desktop-max">
          <div className="flex flex-row items-center h-16 pl-2 pr-2 sm:pl-16 sm:pr-0 lg:pr-16 bg-white text-body1">
            <div className="flex-grow text-left">
              <div className="text-body1">
                <NavLink to={linkPageHome}>Nomikai</NavLink>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div
                onClick={(e) =>
                  this.setState({ expandHeader: !this.state.expandHeader })
                }
                className="pr-6 block md:hidden"
              >
                <i className="pi pi-bars"></i>
              </div>

              <div className="pr-6 hover:text-primary hidden md:block">
                <Link smooth to="/">
                  ホーム
                </Link>
              </div>
              <div className="pr-6 hover:text-primary hidden md:block">
                <Link smooth to="/#howitworks">
                  使い方
                </Link>
              </div>

              {this.props.profile ? (
                <React.Fragment>
                  <div className="pr-6 hover:text-primary hidden md:block">
                    <NavLink to={linkPageDashboard}>始める</NavLink>
                  </div>
                  <div
                    onClick={() => {
                      this.props.dispatch(setProfile(null, ''))
                      this.props.dispatch(setCurrentMeeting(null))
                      this.props.dispatch(setCurrentFeeling(null))
                      FirebaseService.logout()
                      localStorage.clear()
                      window.location.reload()
                    }}
                    className="pr-6 hover:text-primary hidden md:block"
                  >
                    <NavLink to={linkPageHome}>ログアウト</NavLink>
                  </div>
                  <div className="pr-6 hover:text-primary hidden md:block ">
                    <a
                      href="https://www.patreon.com/bePatron?u=35739782"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Nomikaiをサポートする
                    </a>
                  </div>
                  <div className="pr-6 hover:text-primary hidden md:block">
                    <NavLink to={linkPageDashboard}>
                      <img
                        src={
                          this.props.profile && this.props.profile.photoURL
                            ? this.props.profile.photoURL
                            : profileAdd
                        }
                        className="w-10 h-10 ml-auto rounded-full"
                        alt="imgheader"
                      />
                    </NavLink>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NavLink
                    to={linkLogin}
                    className="pr-6 hover:text-primary hidden md:block cursor-pointer hover:text-orange-400"
                  >
                    ログイン
                  </NavLink>
                  <NavLink
                    to={linkSignup}
                    className="pr-6 hover:text-primary hidden md:block cursor-pointer hover:text-orange-400"
                  >
                    登録
                  </NavLink>
                  <div className="pr-6 hover:text-primary hidden md:block">
                    <a
                      href="https://www.patreon.com/bePatron?u=35739782"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Nomikaiをサポートする
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {this.state.expandHeader && (
            <div className="flex flex-col p-4">
              <div className="pr-6 pt-2 pb-2 hover:text-primary block">
                <Link smooth to="/">
                  ホーム
                </Link>
              </div>
              <div className="pr-6 pt-2 pb-2 hover:text-primary block">
                <Link smooth to="/#howitworks">
                  使い方
                </Link>
              </div>

              {this.props.profile ? (
                <React.Fragment>
                  <div className="pr-6 pt-2 pb-2 hover:text-primary block">
                    <NavLink to={linkPageDashboard}>始める</NavLink>
                  </div>
                  <div
                    onClick={() => {
                      this.props.dispatch(setProfile(null, ''))
                      this.props.dispatch(setCurrentMeeting(null))
                      this.props.dispatch(setCurrentFeeling(null))
                      FirebaseService.logout()
                      localStorage.clear()
                      window.location.reload()
                    }}
                    className="pr-6 pt-2 pb-2 hover:text-primary block"
                  >
                    <NavLink to={linkPageHome}>ログアウト</NavLink>
                  </div>
                  <div className="pr-6 pt-2 pb-2 hover:text-primary block">
                    <a
                      href="https://www.patreon.com/bePatron?u=35739782"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Nomikaiをサポートする
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NavLink
                    to={linkLogin}
                    className="pr-6 pt-2 pb-2 hover:text-primary block cursor-pointer hover:text-orange-400"
                  >
                    ログイン
                  </NavLink>
                  <NavLink
                    to={linkSignup}
                    className="pr-6 pt-2 pb-2 hover:text-primary block cursor-pointer hover:text-orange-400"
                  >
                    登録
                  </NavLink>
                  <div className="pr-6 pt-2 pb-2 hover:text-primary block">
                    <a
                      href="https://www.patreon.com/bePatron?u=35739782"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Nomikaiをサポートする
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  currentMeeting: state.CurrentMeetingReducer.currentMeeting,
  currentFeeling: state.CurrentMeetingReducer.currentFeeling,
  profile: state.CurrentMeetingReducer.profile,
})

export default connect(mapStateToProps)(Header)
