import { combineReducers } from 'redux'
import CurrentMeetingReducer from './CurrentMeetingReducer'
import DemoArticleReducer from './DemoArticleReducer'
import DemoListReducer from './DemoListReducer'

const rootReducer = combineReducers({
  DemoArticleReducer,
  DemoListReducer,
  CurrentMeetingReducer,
})

export default rootReducer
