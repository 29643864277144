// import { Growl } from "primereact/growl";
import queryString from 'query-string'
import React, { Component } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import SimpleReactValidator from 'simple-react-validator'
import ProgressiveImage from 'react-progressive-image'
import {
  linkForgotPassword,
  linkPageDashboard,
  linkSignup,
} from '../../../routes'
import AuthService from '../../../services/AuthService'
import FirebaseService from '../../../services/Firebase'
import AuthenticationCode from '../../AuthenticationCode'
import ButtonBox from '../../ButtonBox'
import TextBox from '../../InputText'
import PasswordText from '../../PasswordText'
import GrowlMessage from '../../GrowlMessage'
import Header from '../../../containers/Header'
import loginheader from '../../../assets/img/login1.png'
import loginTiny from '../../../assets/img/login_tiny.png'
// import dashboardCardTiny from "../../../assets/img/dashboard_tiny.png";
import footer from '../../../assets/img/authbgimgcropped.png'
import footerTiny from '../../../assets/img/authbgimgcropped_tiny.png'
interface Props {
  history: any
  location: any
}
interface State {
  email: string
  password: string
  showVerificationCode: boolean
  resendCodeText: string
  message: string
  verificationcode: any
  verificationcodeerror: any
}
class PageLogin extends Component<Props, State> {
  validator: any
  growlSuccess: any
  growlError: any
  growl: any
  isVerificationCodeValid: any
  constructor(props: Props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showVerificationCode: false,
      resendCodeText: '',
      message: '',
      verificationcode: '',
      verificationcodeerror: '',
    }
    this.validate = this.validate.bind(this)
    this.showError = this.showError.bind(this)
    this.clear = this.clear.bind(this)
    this.validator = new SimpleReactValidator({ autoForceUpdate: this })
    // this.resendOTP = this.resendOTP.bind(this)
    this.validateAllThenSubmit = this.validateAllThenSubmit.bind(this)
    this.validateVerificationCodeThenSubmit = this.validateVerificationCodeThenSubmit.bind(
      this,
    )
  }

  showError(msg: string, type: string) {
    if (type === 'success') {
      this.growlSuccess.show({
        severity: type,
        summary: 'エラー',
        detail: msg,
      })
    }
    if (type === 'error') {
      this.growlError.show({
        severity: type,
        summary: 'エラー',
        detail: msg,
      })
    }
  }

  clear() {
    this.growl.clear()
  }

  validate(e: any) {
    this.validator.showMessageFor(e)
  }

  componentDidMount() {
    let user = FirebaseService.getCurrentUser()
    if (user) {
      this.props.history.push('/dashboard')
    } else {
      FirebaseService.getAuthed().onAuthStateChanged((user: any) => {
        if (user) {
          this.props.history.push('/dashboard')
        } else {
          console.log('NO USER....')
        }
      })
    }
  }
  handleVerificationCodeChange() {}
  validateAllThenSubmit() {
    //e.preventDefault();
    if (this.validator.allValid()) {
      const credentials = {
        username: this.state.email,
        password: this.state.password,
      }

      let parsed: any = queryString.parse(this.props.location.search)

      FirebaseService.getAuthed()
        .setPersistence(FirebaseService.getAuthObj().Auth.Persistence.LOCAL)
        .then((res: any) => {
          return FirebaseService.login(credentials)
        })
        .then((res: any) => {
          localStorage.setItem('userInfo', JSON.stringify(res))
          console.log('User Logged In > redirecting to user info page..')
          if (parsed.rto) {
            this.props.history.push(atob(parsed.rto))
          } else {
            this.props.history.push(linkPageDashboard)
          }
        })
        .catch((err: any) => {
          this.showError(err.message, 'error')
          AuthService.logOut()
          FirebaseService.getAuthed()
            .signOut()
            .then(function () {
              // Sign-out successful.
              console.log('signed out')
            })
            .catch(function (error: any) {
              console.log('error signing out')
              // An error happened.
            })
        })
    } else {
      this.validator.showMessages()
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate()
      //alert('The form still has errors!');
    }
  }

  validateVerificationCodeThenSubmit() {
    if (!this.isVerificationCodeValid()) {
      this.setState({
        ...this.state,
        verificationcodeerror: '6 digit verification code required',
      })
      return
    }
    let credentials = {
      username: this.state.email,
      otp: this.state.verificationcode,
    }
    let parsed: any = queryString.parse(this.props.location.search)
    AuthService.activateUser(credentials)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          console.log('Activated User > redirecting to user info page..')
          if (parsed.rto) {
            this.props.history.push(atob(parsed.rto))
          } else {
            this.props.history.push('/console')
          }
        } else {
          this.setState({ message: res.data.message })
          AuthService.logOut()
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          if (err.response.data.message === 'OTP is expired, Regenerate') {
            this.showError("OTP expired. Please use 'Resend Code'", 'error')
          } else {
            this.showError(err.response.data.message, 'error')
          }
        } else {
          this.showError('Incorrect Username / Password', 'error')
        }
        AuthService.logOut()
      })
  }
  /*
  resendOTP() {
    if (this.validator.allValid()) {
      // let parsed = queryString.parse(this.props.location.search);
      const credentials: any = {
        username: this.state.email,
      }
      // resendOTPがなかったから機能してないかも
      AuthService.resendOTP(credentials)
        .then((res: any) => {
          if (res.status === 200) {
            console.log('Created User > redirecting to user info page..')
            this.setState({ ...this.state, resendCodeText: 'Code sent' })
            setTimeout(() => {
              this.setState({ ...this.state, resendCodeText: '' })
            }, 5000)
          } else {
            this.setState({ message: res.data.message })
            AuthService.logOut()
          }
        })
        .catch((err: any) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            if (err.response.data.message === 'OTP is expired, Regenerate') {
              this.showError("OTP expired. Please use 'Resend Code'", 'error')
            } else {
              this.showError(err.response.data.message, 'error')
            }
          }
          AuthService.logOut()
        })
    } else {
      this.validator.showMessages()
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate()
      //alert('The form still has errors!');
    }
  }
  */
  render() {
    return (
      <React.Fragment>
        <GrowlMessage
          successRef={(el: any) => (this.growlSuccess = el)}
          errorRef={(el: any) => (this.growlError = el)}
        />

        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col items-center w-full">
            <Header />
          </div>
          <div className="flex flex-col items-center relative text-center w-full mt-12 sm:w-1/2 xl:w-1/4 fade-in-top mb-8 pt-8 shadow-xl rounded-lg border-2">
            <div className="text-h3">NOMIKAI</div>
            <div className="w-full pt-6 pr-8 pl-8 pb-6">
              <ProgressiveImage src={loginheader} placeholder={loginTiny}>
                {(src: string, loading: boolean) => (
                  <img
                    src={src}
                    alt="nomikai"
                    className="w-full"
                    style={{
                      opacity: loading ? 0.1 : 1,
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
            <div className="w-full">
              <div className="w-full px-10">
                {!this.state.showVerificationCode ? (
                  <div className="grid grid-cols-1 ">
                    <KeyboardEventHandler
                      handleKeys={['enter']}
                      onKeyEvent={(
                        key: string,
                        e: React.KeyboardEvent<HTMLInputElement>,
                      ) => {
                        console.log(`key press detected ${key}`)
                        this.validateAllThenSubmit()
                      }}
                    >
                      <div className="text-center text-h6">
                        <TextBox
                          placeholder="Eメール"
                          value={this.state.email}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({ email: e.target.value })
                          }
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                            this.validate('Email')
                          }
                          validation={this.validator.message(
                            'Email',
                            this.state.email,
                            'required|email',
                          )}
                          className=""
                          textboxtype=""
                          textlabel=""
                          AllowCopy=""
                          data-inviteeindex=""
                        />
                      </div>
                      <div className="text-center text-h6">
                        <PasswordText
                          value={this.state.password}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({ password: e.target.value })
                          }
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                            this.validate('Password')
                          }
                          validation={this.validator.message(
                            'Password',
                            this.state.password,
                            'required',
                          )}
                        />
                      </div>
                    </KeyboardEventHandler>
                    <div className="text-center text-xs">
                      <div className="pt-6 text-button3">
                        <ButtonBox
                          label="ログイン"
                          className=" text-button3 w-full "
                          onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                            this.validateAllThenSubmit()
                          }
                          validation={this.validator.allValid()}
                          buttonSize=""
                          buttonTransformation=""
                          img=""
                          imgalt=""
                          btnColor=""
                          btnColorHover=""
                          btnColorActive=""
                          style={{}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row pt-4 pb-4">
                      <div
                        style={{ flexBasis: 0 }}
                        className="flex-grow cursor-pointer text-left text-h6"
                        onClick={(e) =>
                          this.props.history.push(linkForgotPassword)
                        }
                      >
                        パスワードを忘れた方は
                      </div>
                      <div
                        style={{ flexBasis: 0 }}
                        className="flex-grow cursor-pointer text-right text-h6 text-error"
                        onClick={(e) => this.props.history.push(linkSignup)}
                      >
                        登録する
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="self-center w-full sm:w-sm pt-4 pb-4">
                    <div className="text-h5 text-primary text-center mb-5">
                      Enter the verification code sent to your email id.
                    </div>

                    <KeyboardEventHandler
                      handleKeys={['enter']}
                      onKeyEvent={(key: string, e: any) => {
                        console.log(`key press detected ${key}`)
                        this.validateVerificationCodeThenSubmit()
                      }}
                    >
                      <div>
                        <AuthenticationCode
                          validation={this.state.verificationcodeerror}
                          onChange={this.handleVerificationCodeChange}
                          className=" text-primary "
                        />
                      </div>
                    </KeyboardEventHandler>
                    <div className="text-center text-xs">
                      <div className="text-xl">
                        <ButtonBox
                          label="Verify Code"
                          className=" text-button3 w-full "
                          onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                            this.validateVerificationCodeThenSubmit()
                          }
                          validation={
                            this.state.verificationcode &&
                            this.state.verificationcode.length === 6
                          }
                          buttonSize=""
                          buttonTransformation=""
                          img=""
                          imgalt=""
                          btnColor=""
                          btnColorHover=""
                          btnColorActive=""
                          style={{}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row pt-4">
                      <div
                        style={{ flexBasis: 0 }}
                        className="flex-grow cursor-pointer text-left text-h6"
                        onClick={(e) =>
                          this.setState({
                            ...this.state,
                            showVerificationCode: false,
                            verificationcodeerror: null,
                            verificationcode: null,
                          })
                        }
                      >
                        Back
                      </div>
                      <div
                        style={{ flexBasis: 0 }}
                        className="flex-grow cursor-pointer text-right text-h6 text-error"
                        onClick={(e) => console.log(e)}
                      >
                        Resend Code
                        <div
                          style={{ flexBasis: 0 }}
                          className="flex-grow cursor-pointer text-right text-h6 text-primary"
                        >
                          {this.state.resendCodeText}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full">
              <ProgressiveImage src={footer} placeholder={footerTiny}>
                {(src: string, loading: boolean) => (
                  <img
                    src={src}
                    alt="nomikai"
                    className="w-full"
                    style={{
                      opacity: loading ? 0.1 : 0.8,
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PageLogin
