import { Button } from 'primereact/button'
import React, { Component } from 'react'
// import ReactDom from "react-dom";
interface Props {
  validation: any
  className: string
  buttonSize: string
  buttonTransformation: string
  img: string
  imgalt: string
  label: string
  btnColor: string
  btnColorHover: string
  onClick: any
  btnColorActive: string
  style: any
}
class BtnBox extends Button {
  constructor(props: any) {
    super(props)
    this.renderLabel = this.renderLabel.bind(this)
  }

  renderLabel() {
    if (this.props.img) {
      return null
    }
    var buttonLabel = this.props.label || ''
    if (this.props.label) {
      return (
        <React.Fragment>
          <span className="p-button-text p-c">{buttonLabel}</span>
        </React.Fragment>
      )
    } else {
      return null
    }
  }
}

class ButtonBox extends Component<Props> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        {this.props.validation ? (
          <div className={this.props.className ? ' ' : 'pt-10 pb-2'}>
            <Button
              {...this.props}
              label="Save"
              className={
                (this.props.buttonSize && this.props.buttonSize === 'small'
                  ? ' h-8 '
                  : ' h-12 ') +
                (this.props.buttonTransformation &&
                this.props.buttonTransformation === 'whiteToGrey'
                  ? ' text-white bg-primary border-b2 hover:bg-gray-500 focus:outline-none focus:shadow-outline ' +
                    ' rounded-md transition duration-300 ease-in-out active:bg-gray-700 shadow-lg text-lg '
                  : ' text-white bg-orange-500 border-b2 hover:bg-orange-600 focus:outline-none  ' +
                    ' rounded-md transition duration-300 ease-in-out active:bg-orange-700 shadow-lg text-lg ') +
                (this.props.className
                  ? ' ' + this.props.className + ' '
                  : ' w-full ')
              }
            >
              {this.props.img ? (
                <div className="flex flex-row justify-center items-center">
                  <div>
                    <img
                      className="h-6 pr-3"
                      src={this.props.img}
                      alt={this.props.imgalt ? this.props.imgalt : 'btn image'}
                    />
                  </div>
                  <div>{this.props.label}</div>
                </div>
              ) : (
                ''
              )}
            </Button>
          </div>
        ) : (
          <div className={this.props.className ? ' ' : 'pt-10 pb-2'}>
            <BtnBox
              {...this.props}
              label="Save"
              style={{
                border:
                  this.props.buttonTransformation &&
                  this.props.buttonTransformation === 'whiteToGrey'
                    ? '2px solid'
                    : '',
              }}
              className={
                (this.props.buttonSize && this.props.buttonSize === 'small'
                  ? ' h-10 '
                  : ' h-12 ') +
                (this.props.buttonTransformation &&
                this.props.buttonTransformation === 'whiteToGrey'
                  ? ' text-primary bg-white  border-primary hover:bg-gray-100 focus:outline-none focus:shadow-outline ' +
                    ' rounded-md transition duration-300 ease-in-out active:bg-gray-200 shadow-lg text-field border-b2 '
                  : ` text-white ${
                      this.props.btnColor || `bg-orange-300`
                    } border-b2 hover:${
                      this.props.btnColorHover || `bg-orange-400`
                    } active:${this.props.btnColorHover || `bg-orange-500`} 
                      rounded-md transition duration-300 ease-in-out focus:outline-none shadow-lg text-lg `) +
                (this.props.className
                  ? ' ' + this.props.className + ' '
                  : ' w-full ')
              }
            >
              {this.props.img ? (
                <div className="flex flex-row justify-center items-center">
                  <div>
                    <img
                      className="h-6 pr-3"
                      src={this.props.img}
                      alt={this.props.imgalt ? this.props.imgalt : 'btn image'}
                    />
                  </div>
                  <div>{this.props.label}</div>
                </div>
              ) : (
                ''
              )}
            </BtnBox>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default ButtonBox
