import * as React from 'react'
import AuthService from '../../services/AuthService'
import Firebase from '../../services/Firebase'
import {
  requestSubscription,
  cancelSubscription,
  pay,
  addPaymentMethod,
} from '../../services/StripeService'
import {
  fetchMyMembership,
  Membership,
  PaymentMethod,
  fetchMyAccount,
} from '../../services/MembershipService'
import dayjs from 'dayjs'
import { CardForm } from './CardForm'

const parseSearchParams = (search?: string) => {
  if (!search) {
    return {}
  }
  const params = decodeURIComponent(search).substring(1).split('&')
  return params.reduce((previous: any, cuurent) => {
    const [key, value] = cuurent.split('=')
    if (!previous[key]) {
      previous[key] = value
      return previous
    }
    const existence = previous[key]
    if (existence instanceof Array) {
      existence.push(value)
    } else {
      previous[key] = [existence, value]
    }
    return previous
  }, {})
}

export const StripeDemoPage: React.FC = () => {
  const params = parseSearchParams(window.location.search)
  const auth = AuthService.getUserInfo()
  const uid = auth?.user?.uid
  const [username, setUserName] = React.useState('')
  const [membership, setMembership] = React.useState<Membership>()
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>(
    [],
  )
  const [selectedCardIndex, setSelectedCardIndex] = React.useState(0)
  React.useEffect(() => {
    fetchMyMembership(uid).then((myMembership) => setMembership(myMembership))
    Firebase.getUserProfile(uid).then((result) =>
      setUserName(result?.docs[0]?.data().name),
    )
    fetchMyAccount(uid).then((myPaymentMethods) =>
      setPaymentMethods(myPaymentMethods),
    )
  }, [])

  return (
    <>
      <h1>Hello StripeDemo</h1>
      <br />
      <div>ログイン中のユーザー: {username}</div>
      {membership && membership.expiredAt.isAfter(dayjs()) && (
        <div>
          <div>現在登録中のプラン:{membership.paymentPlan.title}</div>
          <div>
            有効期限:{' '}
            {membership.expiredAt.locale('JP').format('YYYY/MM/DD HH:mm:ss')}
          </div>
          <input
            type="button"
            value="プランを解約する"
            style={{ cursor: 'pointer', width: '50%' }}
            onClick={() =>
              cancelSubscription(uid).then(() => {
                window.location.replace(window.location.pathname)
              })
            }
          />
        </div>
      )}
      <br />
      {params.status === 'success' && <h2>支払いに成功しました。</h2>}
      {params.status === 'cancel' && <h2>支払いをキャンセルしました。</h2>}
      <br />
      <div>
        <div>◆決済情報</div>
        {paymentMethods.length ? (
          <div>
            {paymentMethods.map((pm, index) => (
              <div key={pm.id}>
                <label>
                  <input
                    type="radio"
                    name="card"
                    checked={index === selectedCardIndex}
                    onChange={() => setSelectedCardIndex(index)}
                  />
                  xxxx-xxxx-xxxx-{pm.last4Number} {pm.brand} {pm.expiration}
                </label>
              </div>
            ))}
          </div>
        ) : (
          '支払いに利用するカードを登録してください'
        )}
      </div>
      <br />
      <div>■飲み屋街に月額会員プランで登録する</div>
      <div>
        <input
          type="button"
          value="カードで決済する"
          style={{
            cursor: paymentMethods.length ? 'pointer' : 'not-allowed',
            width: '50%',
          }}
          onClick={() =>
            pay(
              uid,
              'Plan_MONTHLY',
              paymentMethods[selectedCardIndex]?.id,
            ).then(() => {
              window.location.replace(window.location.pathname)
            })
          }
          disabled={!paymentMethods.length}
        />
      </div>
      <br />
      <br />
      <div>■2.5時間チケットを購入する</div>
      <div>
        <input
          type="button"
          value="カードで決済する"
          style={{
            cursor: paymentMethods.length ? 'pointer' : 'not-allowed',
            width: '50%',
          }}
          onClick={() =>
            pay(
              uid,
              'Plan_ONE-TIME',
              paymentMethods[selectedCardIndex]?.id,
            ).then(() => {
              window.location.replace(window.location.pathname)
            })
          }
          // disabled
          disabled={!paymentMethods.length}
        />
      </div>
      <br />
      <br />
      <CardForm
        addPaymentMethod={async (
          cardNumber: string,
          expMonth: string,
          expYear: string,
          cvc: string,
          name: string,
          email: string,
          country: string,
        ) =>
          addPaymentMethod(
            uid,
            cardNumber,
            expMonth,
            expYear,
            cvc,
            name,
            email,
            country,
          )
        }
      />
      <br />
    </>
  )
}
