import * as React from 'react'
import AuthService from '../../services/AuthService'
import Firebase from '../../services/Firebase'
import {
  requestSubscription,
  cancelSubscription,
  pay,
  addPaymentMethod,
} from '../../services/StripeService'
import {
  fetchMyMembership,
  Membership,
  PaymentMethod,
  fetchMyAccount,
} from '../../services/MembershipService'
import dayjs from 'dayjs'

interface CardFormProps {
  addPaymentMethod: (
    cardNumber: string,
    expMonth: string,
    expYear: string,
    cvc: string,
    name: string,
    email: string,
    country: string,
  ) => Promise<void>
}
export const CardForm: React.FC<CardFormProps> = ({ addPaymentMethod }) => {
  const [cardNumber, setCardNumber] = React.useState('4242424242424242')
  const [expMonth, setExpMonth] = React.useState('12')
  const [expYear, setExpYear] = React.useState('2022')
  const [cvc, setCVC] = React.useState('123')
  const [name, setName] = React.useState('TEST NAME')
  const [email, setEmail] = React.useState('test@eample.com')
  const [country, setCountry] = React.useState('JP')
  React.useEffect(() => {}, [])

  return (
    <>
      <h1>◆新しいカードを登録する</h1>
      <br />
      <div>
        ※テスト環境では、下記のカード番号とその他の情報を適当に入力すれば決済できます。
        <p>4242 4242 4242 4242</p>
        <div>
          その他のテスト用カード番号:{' '}
          <a href="https://stripe.com/docs/testing">
            https://stripe.com/docs/testing
          </a>
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <input
          type="text"
          placeholder="email..."
          value={email}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="card number..."
          value={cardNumber}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => {
            if (e.target.value.length <= 16) setCardNumber(e.target.value)
          }}
        />
        <input
          type="text"
          placeholder="expiration month..."
          value={expMonth}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => {
            if (e.target.value.length <= 2) setExpMonth(e.target.value)
          }}
        />
        <input
          type="text"
          placeholder="expiration year..."
          value={expYear}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => {
            if (e.target.value.length <= 4) setExpYear(e.target.value)
          }}
        />
        <input
          type="text"
          placeholder="cvc..."
          value={cvc}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => {
            if (e.target.value.length <= 3) setCVC(e.target.value)
          }}
        />
        <input
          type="text"
          placeholder="name..."
          value={name}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => {
            if (e.target.value.length <= 16) setName(e.target.value)
          }}
        />
        <input
          type="text"
          placeholder="country..."
          value={country}
          style={{ width: '50%', borderWidth: '2px' }}
          onChange={(e) => {
            if (e.target.value.length <= 2) setCountry(e.target.value)
          }}
        />
        <input
          type="button"
          value="カードを登録する"
          style={{
            cursor:
              cardNumber && expYear && expMonth && cvc && name && email
                ? 'pointer'
                : 'not-allowed',
            width: '50%',
          }}
          onClick={() =>
            addPaymentMethod(
              cardNumber,
              expMonth,
              expYear,
              cvc,
              name,
              email,
              country,
            )
          }
        />
      </div>
    </>
  )
}
