import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import BaseApiService from './BaseApiService'

// const USER_API_BASE_URL = "http://localhost:8080/users";
// const PRODUCT_API_BASE_URL = "http://localhost:8086/masterdata/api/es/index/product/search";
const DEFAULT_AUTH_PATH = '/auth/api'

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) =>
  AuthService.refresh(AuthService.getRefreshCredentials()).then(
    (tokenRefreshResponse: any) => {
      var userinfo = AuthService.getUserInfo()
      userinfo.token = tokenRefreshResponse.data.result.token
      localStorage.setItem('userInfo', JSON.stringify(userinfo))
      //AuthService.getUserInfo().token = tokenRefreshResponse.data.result.token;
      //localStorage.setItem('token', tokenRefreshResponse.data.token);
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.result.token
      return Promise.resolve()
    },
  )

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic)

//var axios = axios.create({ baseURL: 'http://amith.local.com:8082/auth/api/' });

class AuthService extends BaseApiService {
  getDefaultApiUrl(): any {
    throw new Error('Method not implemented.')
  }
  static getUserInfo(): any {
    throw new Error('Method not implemented.')
  }
  static getRefreshCredentials(): any {
    throw new Error('Method not implemented.')
  }
  static refresh(arg0: any): any {
    throw new Error('Method not implemented.')
  }
  login(credentials: any) {
    let url = this.getDefaultApiUrl()
    if (credentials.username === 'demo@viamagus.com') {
      return new Promise(function (resolve, reject) {
        if (credentials.password === 'demo') {
          let res = {
            data: {
              status: 200,
              result: 'FAKE_AUTH_TOKEN',
            },
          }
          resolve(res)
        } else {
          let res = {
            data: {
              message: 'Incorrect Credentials.',
            },
          }
          reject(res)
        }
      })
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + btoa(credentials.username + ':' + credentials.password),
    }
    return axios.post(url + DEFAULT_AUTH_PATH + '/token', null, {
      headers: headers,
    })
  }

  signup(credentials: any) {
    let url = this.getDefaultApiUrl()
    const body = {
      username: credentials.username,
      password: credentials.password,
    }
    return axios.post(url + DEFAULT_AUTH_PATH + '/user/register', body)
  }
  activateUser(credentials: any) {
    let url = this.getDefaultApiUrl()
    const body = {
      username: credentials.username,
      otp: credentials.otp,
    }
    return axios.post(url + DEFAULT_AUTH_PATH + '/user/activate', body)
  }
  refresh(credentials: any) {
    return axios.post('token/renew', credentials)
  }

  getUserInfo() {
    const local: any = localStorage
    return JSON.parse(local.getItem('userInfo'))
  }

  getAuthHeader() {
    return {
      headers: { Authorization: 'Bearer ' + this.getUserInfo().accessToken },
    }
  }

  getRefreshCredentials() {
    return {
      username: this.getUserInfo().username,
      refreshToken: this.getUserInfo().refreshtoken,
    }
  }

  logOut() {
    localStorage.removeItem('userInfo')
    return
  }
}

export default new AuthService()
