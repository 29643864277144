//List Actions
export const FETCH_LISTDEMO_BEGIN = 'FETCH_LISTDEMO_BEGIN'
export const FETCH_LISTDEMO_SUCCESS = 'FETCH_LISTDEMO_SUCCESS'
export const FETCH_LISTDEMO_FAILURE = 'FETCH_LISTDEMO_FAILURE'
// Current meeting actions
export const SET_CURRENTMEETING_BEGIN = 'SET_CURRENTMEETING_BEGIN'
export const SET_CURRENTMEETING_SUCCESS = 'SET_CURRENTMEETING_SUCCESS'
export const SET_CURRENTMEETING_FAILURE = 'SET_CURRENTMEETING_FAILURE'

export const GET_CURRENTMEETING_BEGIN = 'GET_CURRENTMEETING_BEGIN'
export const GET_CURRENTMEETING_SUCCESS = 'GET_CURRENTMEETING_SUCCESS'
export const GET_CURRENTMEETING_FAILURE = 'GET_CURRENTMEETING_FAILURE'
// feeling
export const SET_CURRENTFEELING_BEGIN = 'SET_CURRENTFEELING_BEGIN'
export const SET_CURRENTFEELING_SUCCESS = 'SET_CURRENTFEELING_SUCCESS'
export const SET_CURRENTFEELING_FAILURE = 'SET_CURRENTFEELING_FAILURE'
// profile
export const SET_PROFILE_BEGIN = 'SET_PROFILE_BEGIN'
export const SET_PROFILE_SUCCESS = 'SET_PROFILE_SUCCESS'
export const SET_PROFILE_FAILURE = 'SET_PROFILE_FAILURE'

export const GET_PROFILE_BEGIN = 'GET_PROFILE_BEGIN'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
//Other Actions...
