import React from 'react'
import { StripeDemoPage } from './demo/stripe'
//links
export const linkPageHome = '/'
export const linkPageDashboard = '/dashboard'
export const linkPageProfile = '/profile'
export const linkSignup = '/signup'
export const linkPageCreateAMeeting = '/createmeeting'
export const linkPageMeeting = '/meeting'
export const linkPagePreMeeting = '/premeeting'
export const linkForgotPassword = '/forgotpassword'
export const linkLogin = '/login'
//linknames
export const linkNamePageHome = 'Home'
export const linkNamePageDashboard = 'Dashboard'
export const linkNamePageProfile = 'Profile'
export const linkNameSignup = 'Sign Up'
export const linkNamesCreateAMeeting = 'Create a Meeting'
export const linkNamesMeeting = 'Meeting'
export const linkNamesPreMeeting = 'Pre Meeting'
export const linkNameForgotPassword = 'Forgot Password'
//imports
// const PageHome = React.lazy(() => import('./components/views/PageHome'))
const View1 = React.lazy(() => import('./components/views/View1'))
const View2 = React.lazy(() => import('./components/views/View2'))
const PageDashboard = React.lazy(
  () => import('./components/views/PageDashboard'),
)
const PageProfile = React.lazy(() => import('./components/views/PageProfile'))
const CreateAMeeting = React.lazy(
  () => import('./components/views/PageCreateAMeeting'),
)
const PageMeeting = React.lazy(() => import('./components/views/PageMeeting'))
const PagePreMeeting = React.lazy(
  () => import('./components/views/PagePreMeeting'),
)
const routes = [
  {
    path: '/demo/stripe',
    exact: true,
    name: 'stripe-demo',
    component: StripeDemoPage,
  },
  { path: '/console', exact: true, name: 'Home', component: View1 },
  { path: '/console/view2', name: 'View2', component: View2 },
  {
    path: linkPageDashboard,
    exact: true,
    name: linkNamePageDashboard,
    component: PageDashboard,
  },
  {
    path: linkPageProfile,
    exact: true,
    name: linkNamePageProfile,
    component: PageProfile,
  },
  {
    path: linkPageCreateAMeeting,
    exact: true,
    name: linkNamesCreateAMeeting,
    component: CreateAMeeting,
  },
  {
    path: linkPageMeeting,
    exact: true,
    name: linkNamesMeeting,
    component: PageMeeting,
  },
  {
    path: linkPagePreMeeting,
    exact: true,
    name: linkNamesPreMeeting,
    component: PagePreMeeting,
  },
]

export default routes
