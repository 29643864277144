import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import React, { Component } from 'react'
import error from '../../assets/img/error.svg'
import iconEyeClosed from '../../assets/img/eye_close.svg'
import iconEyeRed from '../../assets/img/eye_red.svg'
interface Props {
  validation: any
  value: string
  onChange: any
  onBlur: any
}
interface State {
  showPassword: boolean
}
class PasswordText extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showPassword: false,
    }
    this.toggleViewPassword = this.toggleViewPassword.bind(this)
  }

  toggleViewPassword(e: any) {
    e.preventDefault()
    this.setState({
      showPassword: !this.state.showPassword,
    })
    //alert(this.state.showPassword);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showPassword ? (
          <div className="pb-2 pt-2">
            <div className="flex flex-row align-middle">
              <InputText
                className={
                  'transition duration-150 ease-out focus:border-orange-500 text-left text-gray-600 text-field ' +
                  ' w-full border border-gray-500 placeholder-gray-600 bg-transparent '
                }
                placeholder="パスワード"
                {...this.props}
              />
              <div className="flex align-middle -m-8">
                <img
                  className="w-5 h-auto self-center"
                  src={iconEyeRed}
                  alt="logo"
                  onClick={(e) => {
                    this.toggleViewPassword(e)
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row align-middle mt-1 text-xs h-3 text-left text-red-txt">
              <div className="pr-1">
                {this.props.validation ? (
                  <img className="w-4" src={error} alt="error" />
                ) : (
                  ''
                )}
              </div>
              <div>{this.props.validation}</div>
            </div>
          </div>
        ) : (
          <div className="pb-2 pt-2">
            <div className="flex flex-row align-middle">
              <Password
                className={
                  'transition duration-150 ease-out focus:border-orange-500 text-left text-gray-600 text-field ' +
                  ' w-full border border-gray-500 placeholder-gray-600 bg-transparent '
                }
                placeholder="パスワード"
                {...this.props}
              />
              <div className="flex align-middle -m-8">
                <img
                  className="w-5 h-auto self-center"
                  src={iconEyeClosed}
                  alt="logo"
                  onClick={(e) => {
                    this.toggleViewPassword(e)
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row align-middle mt-1 text-xs h-3 text-left text-red-txt">
              <div className="pr-1">
                {this.props.validation ? (
                  <img className="w-4" src={error} alt="error" />
                ) : (
                  ''
                )}
              </div>
              <div>{this.props.validation}</div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default PasswordText
