import firebase from 'firebase'
import BaseApiService from './BaseApiService'

// var firebaseConfig = {
//   apiKey: "AIzaSyADBKA46k0Q8XhOSYnfp7ciDa0BKbMECiM",
//   authDomain: "react-fire-e5029.firebaseapp.com",
//   databaseURL: "https://react-fire-e5029.firebaseio.com",
//   projectId: "react-fire-e5029",
//   storageBucket: "react-fire-e5029.appspot.com",
//   messagingSenderId: "163051524564",
//   appId: "1:163051524564:web:6b56a2bf1666d3802ac641",
//   measurementId: "G-Z65HXNTQF4",
// };

var firebaseConfig = {
  apiKey: 'AIzaSyARjEeYXDKa8eBXSDBAk_qtnYijC4tDq88',
  authDomain: 'react-fire-50d4b.firebaseapp.com',
  databaseURL: 'https://react-fire-50d4b.firebaseio.com',
  projectId: 'react-fire-50d4b',
  storageBucket: 'react-fire-50d4b.appspot.com',
  messagingSenderId: '979474766512',
  appId: '1:979474766512:web:511b6e480e6f46ad2d3975',
  measurementId: 'G-0C6FXCVW1T',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const auth = firebase.auth
export const db = firebase.database()
export const firestore = firebase.firestore()
export const storageRef = firebase.storage().ref()

class FirebaseService extends BaseApiService {
  signup(credentials: any) {
    var email = credentials.username
    var password = credentials.password
    return auth().createUserWithEmailAndPassword(email, password)
  }

  login(credentials: any) {
    var email = credentials.username
    var password = credentials.password
    return auth().signInWithEmailAndPassword(email, password)
  }
  logout() {
    auth().signOut()
  }
  forgotPassword(emailAddress: any) {
    return auth().sendPasswordResetEmail(emailAddress)
  }

  updateUserProfile(profile: any, docId: any) {
    var { uid, name, nickname, email, dob, description, photoURL } = profile
    var user: any = this.getCurrentUser()
    uid = user.uid
    if (docId) {
      //existing user
      return this.getFirestore().collection('users').doc(docId).set({
        uid: uid,
        name: name,
        nickname: nickname,
        email: email,
        dob: dob,
        description: description,
        photoURL: photoURL,
      })
    } else {
      //new user
      return this.getFirestore().collection('users').add({
        uid: uid,
        name: name,
        nickname: nickname,
        email: email,
        dob: dob,
        description: description,
        photoURL: photoURL,
      })
    }
  }

  getUserProfile(uid: any) {
    return this.getFirestore().collection('users').where('uid', '==', uid).get()
  }

  getUserProfileFromEmailId(email: any) {
    return this.getFirestore()
      .collection('users')
      .where('email', '==', email)
      .get()
  }

  createNewMeeting(meetingObj: any, docId: any) {
    var { invitees } = meetingObj
    var user: any = this.getCurrentUser()
    var hostemail = user.email
    var hostnickname = user.displayName
    var hostuid = user.uid

    invitees.push(hostemail) // to easily find meetings for all people involved
    if (docId) {
      return this.getFirestore()
        .collection('meetings')
        .doc(docId)
        .set({
          ...meetingObj,
          hostemail,
          hostnickname,
          hostuid,
        })
    }
    return this.getFirestore()
      .collection('meetings')
      .add({
        ...meetingObj,
        hostemail,
        hostnickname,
        hostuid,
      })
  }

  deleteMeeting(docId: any) {
    if (docId) {
      return this.getFirestore().collection('meetings').doc(docId).delete()
    } else {
      return Promise.reject('Meeting Id Missing')
    }
  }

  getCurrentMeetingDetails(meetingId: any) {
    if (meetingId) {
      return this.getFirestore().collection('meetings').doc(meetingId).get()
    } else {
      return Promise.reject('No Meeting Found')
    }
  }

  getMeetings(date: Date, beforeAfter: string) {
    var user: any = this.getCurrentUser()

    if (beforeAfter === 'before') {
      return this.getFirestore()
        .collection('meetings')
        .where('invitees', 'array-contains', user.email)
        .where('date', '<', date)
        .get()
    } else {
      return this.getFirestore()
        .collection('meetings')
        .where('invitees', 'array-contains', user.email)
        .where('date', '>=', date)
        .get()
    }
  }

  getAllMeetings() {
    var user: any = this.getCurrentUser()

    return this.getFirestore()
      .collection('meetings')
      .where('invitees', 'array-contains', user.email)
      .get()
  }

  uploadImage(relativeUrlWithFileName: any, file: any) {
    return storageRef.child(relativeUrlWithFileName).put(file)
  }

  getInMeetingDetailsCallbackObj(meetingId: any) {
    return this.getDb().ref('inmeetingdetails/' + meetingId)
  }

  updateInMeetingDetails(inMeetingDetails: any, meetingId: any) {
    var user: any = this.getCurrentUser()
    /*
    var {
      currentFeeling,
      currentEmoji,
      currentDrink,
      drinkHistory,
    } = inMeetingDetails; //drinkHistory is an array
    */
    return this.getDb()
      .ref('inmeetingdetails/' + meetingId + '/' + user.uid)
      .set({
        meetingId,
        uid: user.uid,
        displayName: user.displayName,
        ...inMeetingDetails,
      })
  }

  //** Management Functions **/

  getAuthed(): any {
    return auth()
  }

  getAuthObj() {
    return auth
  }

  getCurrentUser() {
    return auth().currentUser
  }

  getDb() {
    return db
  }

  getFirestore() {
    return firestore
  }
}

export default new FirebaseService()
