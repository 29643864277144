import dayjs, { Dayjs } from 'dayjs'
import Axios from 'axios'

const apiUrl = (path: string) => {
  const baseUrl =
    window.location.hostname === 'localhost'
      ? `https://y3as74glbh.execute-api.ap-northeast-1.amazonaws.com/test`
      : `https://0ze6c8qhv6.execute-api.ap-northeast-1.amazonaws.com/develop`
  return `${baseUrl}/${path}`
}
type PaymentType = 'MONTHLY_SUBSCRIPTION' | 'ONE-TIME_PAYMENT'
export interface PaymentPlan {
  id: string
  type: PaymentType
  price: number
  title: string
  description: string
}

class PaymentPlanModel implements PaymentPlan {
  constructor(
    readonly id: string,
    readonly type: PaymentType,
    readonly price: number,
    readonly title: string,
    readonly description: string,
  ) {}

  static fromMembershipResponse(obj: PaymentPlan) {
    return new PaymentPlanModel(
      obj.id,
      obj.type,
      obj.price,
      obj.title,
      obj.description,
    )
  }
}

export interface Membership {
  userId: string
  paymentPlan: PaymentPlan
  expiredAt: Dayjs
}

class MembershipModel implements Membership {
  constructor(
    readonly userId: string,
    readonly paymentPlan: PaymentPlan,
    readonly expiredAt: Dayjs,
  ) {}
}

export const fetchMyMembership = async (userId: string) => {
  const response = await Axios.get(apiUrl(`membership/${userId}`))
  if (response.status !== 200) {
    console.error(response.data.error)
    throw new Error('request failed.')
  }
  const { data } = response.data
  if (!data) return undefined
  return new MembershipModel(
    userId,
    PaymentPlanModel.fromMembershipResponse(data?.paymentPlan),
    dayjs(data?.expiredAt),
  )
}

export interface PaymentMethod {
  id: string
  expiration: string
  last4Number: string
  brand: string
}

class PaymentMethodModel implements PaymentMethod {
  private constructor(
    readonly id: string,
    readonly expirationMonth: number,
    readonly expirationYear: number,
    readonly last4Number: string,
    readonly brand: string,
  ) {}

  static fromResponse(obj: any) {
    return new PaymentMethodModel(
      obj.id,
      obj.expirationMonth,
      obj.expirationYear,
      obj.last4Number,
      obj.brand,
    )
  }
  get expiration() {
    return `${this.expirationYear}/${this.expirationMonth}`
  }
}
export const fetchMyAccount = async (userId: string) => {
  const response = await Axios.get(apiUrl(`account/${userId}`))
  if (response.status != 200) {
    console.error(response.data.error)
    throw new Error('request failed.')
  }
  console.log(response)
  const paymentMethods = response.data.data?.paymentMethods || []
  return paymentMethods.map((pm: PaymentMethod) =>
    PaymentMethodModel.fromResponse(pm),
  )
}
