import {
  GET_PROFILE_BEGIN,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  SET_CURRENTFEELING_BEGIN,
  SET_CURRENTFEELING_FAILURE,
  SET_CURRENTFEELING_SUCCESS,
  SET_CURRENTMEETING_BEGIN,
  SET_CURRENTMEETING_FAILURE,
  SET_CURRENTMEETING_SUCCESS,
  SET_PROFILE_BEGIN,
  SET_PROFILE_FAILURE,
  SET_PROFILE_SUCCESS,
  GET_CURRENTMEETING_BEGIN,
  GET_CURRENTMEETING_FAILURE,
  GET_CURRENTMEETING_SUCCESS,
} from '../actions/1_ActionConstants'

const initialState = {
  currentMeeting: null,
  currentFeeling: null,
  profile: null,
  loading: false,
  errormsg: null,
}

export default function CurrentMeetingReducer(
  state = initialState,
  action: any,
) {
  switch (action.type) {
    case SET_CURRENTMEETING_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      console.log('setting current meeting - begin')
      return {
        ...state,
        loading: true,
        currentMeeting: null,
      }

    case SET_CURRENTMEETING_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      console.log(
        'setting current meeting - success: ',
        action.payload.currentMeeting,
      )
      return {
        ...state,
        loading: false,
        currentMeeting: action.payload.currentMeeting,
      }

    case SET_CURRENTMEETING_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log('setting current meeting - failed')
      return {
        ...state,
        loading: false,
        errormsg: 'Could not get current meeting',
        currentMeeting: null,
      }

    case GET_CURRENTMEETING_BEGIN:
      console.log('getting current meeting - begin')
      return {
        ...state,
        loading: true,
        currentMeeting: null,
      }

    case GET_CURRENTMEETING_SUCCESS:
      console.log(
        'getting current meeting - success: ',
        action.payload.currentMeeting,
      )
      return {
        ...state,
        loading: false,
        currentMeeting: action.payload.currentMeeting,
      }

    case GET_CURRENTMEETING_FAILURE:
      console.log('getting current meeting - failed')
      return {
        ...state,
        loading: false,
        errormsg: 'Could not get current meeting',
        currentMeeting: null,
      }

    case SET_CURRENTFEELING_BEGIN:
      console.log('setting current feeling - begin')
      return {
        ...state,
        loading: true,
        currentFeeling: null,
      }

    case SET_CURRENTFEELING_SUCCESS:
      console.log(
        'setting current feeling - success: ',
        action.payload.currentFeeling,
      )
      return {
        ...state,
        loading: false,
        currentFeeling: action.payload.currentFeeling,
      }

    case SET_CURRENTFEELING_FAILURE:
      console.log('setting current feeling - failed')
      return {
        ...state,
        loading: false,
        errormsg: 'Could not get current meeting',
        currentFeeling: null,
      }

    //profile
    case SET_PROFILE_BEGIN:
      console.log('setting profile - begin')
      return {
        ...state,
        loading: true,
        profile: null,
      }

    case SET_PROFILE_SUCCESS:
      console.log('setting profile - success: ', action.payload.profile)
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
      }

    case SET_PROFILE_FAILURE:
      console.log('setting profile - failed')
      return {
        ...state,
        loading: false,
        errormsg: 'Could not set profile',
        profile: null,
      }

    case GET_PROFILE_BEGIN:
      console.log('setting profile - begin')
      return {
        ...state,
        loading: true,
        profile: null,
      }

    case GET_PROFILE_SUCCESS:
      console.log('setting profile - success: ', action.payload.profile)
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
      }

    case GET_PROFILE_FAILURE:
      console.log('setting profile - failed')
      return {
        ...state,
        loading: false,
        errormsg: 'Could not get profile',
        profile: null,
      }

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
