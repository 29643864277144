import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loader from './components/Loader'
import PageLogin from './components/views/PageLogin'
//link names
import {
  linkForgotPassword,
  linkNameForgotPassword,
  linkNamePageHome,
  linkNameSignup,
  linkSignup,
} from './routes'
import store from './store'

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const PageHome = React.lazy(() => import('./components/views/PageHome'))
const Page404 = React.lazy(() => import('./components/views/Page404'))
const SignUp = React.lazy(() => import('./components/views/PageSignup'))
const ForgotPassword = React.lazy(
  () => import('./components/views/PageForgotPassword'),
)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router basename="/">
          <React.Suspense fallback={<Loader style={{}} loadingText="" />}>
            <Switch>
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props: any) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/login"
                name="Login"
                render={(props: any) => <PageLogin {...props} />}
              />
              {/** Other Un-Authenticated Pages Start */}
              <Route
                exact
                path={linkSignup}
                name={linkNameSignup}
                render={(props: any) => <SignUp {...props} />}
              />
              <Route
                exact
                path={linkForgotPassword}
                name={linkNameForgotPassword}
                render={(props: any) => <ForgotPassword {...props} />}
              />
              {/** Other Un-Authenticated Pages End */}
              <Route
                exact
                path="/"
                name={linkNamePageHome}
                render={(props: any) => <PageHome {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props: any) => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </Provider>
    )
  }
}

export default App
