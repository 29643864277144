import axios from 'axios'
import AuthService from './AuthService'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const prod = true

const DEFAULT_API_PATH = 'http://admin.atq.viamagus.me'
// const DEFAULT_PARTNER_API_PATH = "http://partner.atq.viamagus.me";

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) =>
  AuthService.refresh(AuthService.getRefreshCredentials()).then(
    (tokenRefreshResponse) => {
      var userinfo = AuthService.getUserInfo()
      userinfo.token = tokenRefreshResponse.data.result.token
      localStorage.setItem('userInfo', JSON.stringify(userinfo))
      //AuthService.getUserInfo().token = tokenRefreshResponse.data.result.token;
      //localStorage.setItem('token', tokenRefreshResponse.data.token);
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.result.token
      return Promise.resolve()
    },
  )

createAuthRefreshInterceptor(axios, refreshAuthLogic)

axios.interceptors.request.use(
  function (config) {
    console.log('Making API Request...')
    // Do something before request is sent
    return config
  },
  function (error) {
    console.log('API Request ERROR...')
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    console.log('API Response received...')
    // Do something with response data
    return response
  },
  function (error) {
    console.log('API Response ERROR...')
    // Do something with response error
    return Promise.reject(error)
  },
)

class BaseApiService {
  getDefaultApiUrl() {
    if (process.env.NODE_ENV === 'production') {
      if (!(typeof window === 'undefined')) {
        return window.location.protocol + '//' + window.location.hostname
      }
    }
    return DEFAULT_API_PATH
  }

  getPartnerDomainName() {
    if (process.env.NODE_ENV === 'production') {
      if (!(typeof window === 'undefined')) {
        const regex = /^admin/gi
        return (
          window.location.protocol +
          '//' +
          window.location.hostname.replace(regex, 'partner')
        )
      }
    }
    return this.getDefaultApiUrl().replace(/.*:\/\/admin/gi, 'partner')
  }

  getAxios() {
    if (!prod) {
      return this.dummyAxios()
    }

    return axios
  }

  getAuthed() {
    return AuthService.getAuthHeader()
  }

  getDummyData() {
    let res = {
      status: 200,
      data: {
        message: 'Dummy Data',
      },
    }
    return res
  }

  isApiEnvDev() {
    return !prod
  }

  dummyAxios() {
    console.log('Executing in DUMMY API MODE...')
    let obj: any = {
      get: (url = '', auth = '', obj: any = this) => {
        try {
          console.log('Call list-> Step 1')
          var stack: any = new Error().stack
          console.log('Call list->' + stack)
          var caller = stack.split('\n')[2].trim()
          var fn = caller.split(' ')
          var methods = fn[1].split('.')
          var results = obj[methods[1] + 'Dummy']()
          if (results) {
            return new Promise(function (resolve, reject) {
              resolve(results)
            })
          }
        } catch (err) {
          console.log('ERROR...')
          console.log('ERROR: ' + err)
          console.log('Error -> ' + err.stack)
          console.log(
            'Function ' +
              fn[1] +
              'Dummy() does not seem to exist. Executing default getDummyData() function. ',
          )
        }
        return new Promise(function (resolve, reject) {
          resolve({
            status: 200,
            data: [{ id: 1, name: 'DUMMY NAME', value: 'DUMMY VALUE' }],
            message: 'dummy get api success',
          })
        })
      },
      post: (url = '', auth = '', obj: any = this) => {
        try {
          var stack: any = new Error().stack
          var caller = stack.split('\n')[2].trim()
          var fn = caller.split(' ')
          var methods = fn[1].split('.')
          var results = obj[methods[1] + 'Dummy']()
          if (results) {
            return new Promise(function (resolve, reject) {
              resolve(results)
            })
          }
        } catch (err) {
          console.log(
            'Function ' +
              fn[1] +
              'Dummy() does not seem to exist. Executing default getDummyData() function. ',
          )
        }
        return new Promise(function (resolve, reject) {
          resolve({
            status: 200,
            message: 'dummy post api success',
          })
        })
      },
    }
    return obj
  }
}

export default BaseApiService
